/**
 * External Dependencies
 */
import 'jquery';
import 'is-in-viewport'
import Masonry from 'masonry-layout'
import {domReady} from '@roots/sage/client';

import Swiper, { Navigation, Pagination } from 'swiper';

/**
 * app.main
 */
const main = async (err) => {
	if (err) {
		// handle hmr errors
		console.error(err);
	}

	jQuery(function($) {
		function swiper() {
			new Swiper('.home-swiper', {
				modules: [Navigation],
				loop: true,
				centeredSlides: true,
				autoplay: {
					delay: 5000,
				},
				navigation: {
					nextEl: '.home-swiper .next',
					prevEl: '.home-swiper .prev',
				},
			});

			$(".standard-swiper").each(function(){
				var $this = $(this);
				new Swiper(this, {
					modules: [Navigation, Pagination],
					loop: true,
					speed: 600,
					autoHeight: true,
					grabCursor: true,
					spaceBetween: 30,
					touchEventsTarget: 'wrapper',
					navigation: {
						nextEl: $this.find('.nav .next')[0],
						prevEl: $this.find('.nav .prev')[0],
					},
					pagination: {
						el: $this.find('.swiper-pagination')[0],
						type: 'bullets',
						clickable: true,
					},
				});
			});
		}

		function navigation() {
			$('body').on('click','.hamburger', function(e){
				e.preventDefault();
				$('.hamburger, .overlay-menu, body').toggleClass('is-active');

				$('body').on('click', 'main', function(e){
					console.log('test');
					if(e.target != document.querySelector('overlay-menu')) {
						$('.hamburger, .overlay-menu, body').removeClass('is-active');
					}
				});
			});

			$('li.menu-item-has-children > a').on('click',function(event){
				event.preventDefault()

				$(this).parent().find('ul').first().toggleClass('active');

				$(this).parent().siblings().find('ul').removeClass('active');

				//Hide menu when clicked outside
				$(this).parent().find('ul').mouseleave(function(){
					var thisUI = $(this);
					$('html').click(function(){
						thisUI.removeClass('active');
						$('html').unbind('click');
					});
				});
			});
		}

		function tabs() {
			$('.tabs-js').each(function(){
				$(this).find('.tab-content').hide();
				$(this).find('.tab-content:first').show().addClass('tab-open');
				$(this).find('[data-tab]:first').addClass('active');
			});

			$('[data-tab]').click(function() {
				$(this).closest('.tabs-js').find('[data-tab]').removeClass('active');
				$(this).addClass('active');
				$(this).closest('.tabs-js').find('.tab-content').hide().removeClass('tab-open');

				var selectTab = $(this).attr('data-tab');
				$(selectTab).fadeIn().addClass('tab-open');
			});

			$('.tab-select').on('change', function() {
				$('.tab-content').hide();

				var selectTab = $(this).val();

				$(selectTab).fadeIn();
			});
		}

		function accordion() {
			$('.accordion-title').click(function(j) {
				var dropDown = $(this).closest('.accordion').find('.accordion-content');

				$(this).closest('#accordion').find('.accordion-content').not(dropDown).slideUp();

				if ($(this).hasClass('open')) {
					$(this).removeClass('open');
				} else {
					console.log('test');
					$(this).closest('#accordion').find('.accordion-title.open').removeClass('open');
					$(this).addClass('open');
				}

				dropDown.stop(false, true).slideToggle();

				j.preventDefault();
			});
		}

		function modal() {
			$('[data-open]').on('click', function(){
				var id = $(this).data('open');
				$('body').toggleClass('modal-open');

				$('#'+id).toggleClass('active');
			});

			$('body').on('click', '.modal .close, .continue', function () {
				$('.modal.active').removeClass('active');
				$('body').removeClass('modal-open');
			});
		}

		function isVisible() {
			viewportDetection();

			$( window ).scroll( function() {
				viewportDetection();
			});

			function viewportDetection() {
				$( '[data-viewport="detect"]:in-viewport' ).addClass( 'visible' );
			}
		}

		function fixedHeader() {
			var headerHeight = $('header').height();
			$(window).scroll(function(){
				if ($(window).scrollTop() >= headerHeight) {
					$('header').addClass('fixed');
				}
				else {
					$('header').removeClass('fixed');
				}
			});
		}

		function masonryGrid() {
			if($('.grid').length) {
				var elem = document.querySelector('.grid');
				new Masonry( elem, {
					// options
					itemSelector: '.grid-item',
					gutter: 15,
				});
			}
		}

		function loadMore() {
			$(document).on('click', ".load-more a", function (e) {
				e.preventDefault();
				var link = $(this).attr('href');
				$('.load-more a').text('Loading...');

				$.get(link, function(data) {
					var post = $("#posts .post-item ", data);
					$('#posts').append(post);
				});

				$('.load-more').load(link+' .load-more a');
			});
		}

		$(document).ready(() => {
			swiper();
			navigation();
			accordion();
			modal();
			isVisible();
			loadMore();
			fixedHeader();
			tabs();
			masonryGrid();
		});
	});
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
